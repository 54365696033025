import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useSignup } from "../hooks/useSignup";
import axios from "axios";
import CreatableSelect from "react-select/creatable";
import Loading from "../components/Loading";

// import axios from "../config/axios";

// LOGIIN
const Signup = () => {
  const [userName, setUserName] = useState("");
  const [age, setAge] = useState("");
  const [organization, setOrganization] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [allOrganizations, setAllOrganizations] = useState([]);
  const { signup, error, isLoading } = useSignup();

  const handleSubmit = async (e) => {
    e.preventDefault();
    await signup(userName, age, organization, email, password);
  };

  useEffect(() => {
    const getOrganizations = async () => {
      try {
        const { data } = await axios.get("/api/challenges/organizations");
        console.log("data:", data);
        if (data) {
          setAllOrganizations(data);
        }
      } catch (err) {
        console.error("Failed to fetch organizations: ", err);
        // You could also set some state to display the error on your page
      }
    };

    getOrganizations();
  }, []); // Empty dependency array means this effect runs once on mount

  const handleSelectChange = (selectedOption) => {
    setOrganization(selectedOption.value);
  };

  const colors = {
    orange: "#E1664F",
    darkBrown: "#4F4C48",
    grey: "#A7A29E",
    black: "#1B1917",
  };

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused ? colors.orange : "", // replace with your colors
      color: state.isFocused ? colors.black : colors.grey,
    }),
    input: (provided) => ({
      ...provided,
      color: colors.grey,
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: colors.black,
      borderColor: colors.orange,
      borderWidth: "1px", // Set your desired border width here
      borderRadius: "4px",
    }),
    control: (provided) => ({
      ...provided,
      backgroundColor: colors.black,
      borderColor: colors.darkBrown,
      borderRadius: "4px",
      paddingTop: "6px",
      paddingBottom: "6px",
      marginBottom: "14px",
      color: colors.orange,
      fontWeight: "400",
      width: "100%", // Add this line

      "&:hover": {
        borderColor: colors.orange,
      },
      "&:focus": {
        borderColor: colors.orange,
        outline: "none",
        boxShadow: "none",
      },
    }),
    singleValue: (provided) => ({
      ...provided,
      color: colors.orange,
    }),
    placeholder: (provided) => ({
      ...provided,
      color: colors.grey,
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      color: colors.orange,
    }),
  };

  if (!allOrganizations.length) {
    return <Loading />;
  } else {
    return (
      <div className=" bg-black h-screen grid place-content-center">
        <form
          className="p-6 sm:p-10 rounded-xl bg-stone-900 w-fit"
          onSubmit={handleSubmit}
        >
          <h3 className="font-bold text-3xl text-center pb-4 text-white">
            Sign up
          </h3>
          <h6 className="font-light text-lg text-center pb-8 text-white">
            Sign up and challenge the world or{" "}
            <Link to="/login">
              <span className="text-red underline">Login</span>
            </Link>
          </h6>

          <div className="grid place-items-center p-4">
            <input
              type="text"
              placeholder="Full Name"
              className="w-full rounded p-3 text-stone-300 pr-20 placeholder:text-stone-400 mb-4 block bg-stone-900 border-stone-600 border hover:border-red  outline-none focus:border-red "
              onChange={(e) => setUserName(e.target.value)}
              value={userName}
            />

            <CreatableSelect
              isClearable
              onChange={handleSelectChange}
              options={allOrganizations}
              placeholder="Organization (optional)"
              styles={customStyles}
              className="w-full"
            />

            <input
              type="number"
              placeholder="Age (optional)"
              className="rounded p-3 w-full text-stone-300 pr-20 placeholder:text-stone-400 mb-4 block bg-stone-900 border-stone-600 border hover:border-red  outline-none focus:border-red "
              onChange={(e) => setAge(e.target.value)}
              value={age}
            />

            <input
              type="email"
              placeholder="Email"
              className="rounded p-3 w-full text-stone-300 pr-20 placeholder:text-stone-400 mb-4 block bg-stone-900 border-stone-600 border hover:border-red  outline-none focus:border-red "
              onChange={(e) => setEmail(e.target.value)}
              value={email}
            />

            <input
              type="password"
              placeholder="Password"
              className="rounded p-3 w-full text-stone-300 pr-20 placeholder:text-stone-400 mb-4 block bg-stone-900 border-stone-600 border hover:border-red  outline-none focus:border-red "
              onChange={(e) => setPassword(e.target.value)}
              value={password}
            />

            <button
              className="bg-red py-3 w-full  rounded px-28 hover:bg-red  text-stone-900 font-semibold  hover:text-stone-200"
              disabled={isLoading}
            >
              Sign up
            </button>
            {error && <div className="text-stone-300 pt-4">{error}</div>}
          </div>
        </form>
      </div>
    );
  }
};

export default Signup;
