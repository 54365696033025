import React, { useState } from "react";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useAuthContext } from "../hooks/useAuthContext";
import { useChallengesContext } from "../hooks/useChallengeContext";
import AWS from "aws-sdk";
import axios from "../config/axios";
import * as Sentry from "@sentry/react";
import { uploadFileToS3 } from "../services/upload.service";
import { Snackbar, Alert } from "@mui/material";
const { v4: uuidv4 } = require("uuid");

const s3 = new AWS.S3({
  region: process.env.REACT_APP_BUCKET_REGION,
  accessKeyId: process.env.REACT_APP_ACCESS_KEY,
  secretAccessKey: process.env.REACT_APP_SECRET_ACCESS_KEY,
});

const CreateChild = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { user } = useAuthContext();
  const { challengeDispatch } = useChallengesContext();
  const [uploadStart, setUploadStart] = useState(false);
  const [uploadFinish, setUploadFinish] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [reps, setReps] = useState("");
  const [file, setFile] = useState(null);
  const [progress, setProgress] = useState(0);
  const [isloading, setIsLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [isFileSize, setIsFileSize] = useState(true);
  const fileRef = React.useRef(null);
  const [snackbarOpen, setSnackbarOpen] = React.useState(false);
  const connectionErrMsg =
    "Your connection dropped please upload your video again!";
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setSnackbarOpen(false);
  };
  function checkFileSize(file) {
    // Check the size of the file in bytes
    const fileSizeInBytes = file.size;

    // Convert the file size from bytes to megabytes
    const fileSizeInMB = fileSizeInBytes / 1024 / 1024;

    // Set the state variable to true if the file is larger than 500MB
    if (fileSizeInMB > 500) {
      setIsFileSize(false);
      setErrorMsg("Please upload a video that is less than 500mb");
    }
  }

  const handleFileChange = (event) => {
    const selectedFile = fileRef?.current?.files[0] || event.target.files[0];
    checkFileSize(selectedFile);
    setFile(selectedFile);
  };

  //   const handleSubmit = async (event) => {
  //     event.preventDefault();

  //     if (!file) {
  //       return;
  //     }

  //     const uuid = uuidv4();
  //     const fileName = `${uuid}.mp4`

  //     try {

  //       const params = {
  //         Bucket: process.env.REACT_APP_BUCKET_NAME,
  //         Key: fileName,
  //         Body: file,
  //         partSize: 5 * 1024 * 1024, // 5MB chunk sizes.
  //         queueSize: 1 // Number of concurrent upload requests
  //       };

  //       await s3.upload(params)
  //       .on('httpUploadProgress', (progress) => {
  //           const percent = Math.round((progress.loaded / progress.total) * 100);
  //           console.log(percent)
  //           setProgress(percent);
  //         })
  //       .promise()
  //       .then(() => {
  //           // This function will be called when the upload is complete
  //           console.log('Upload finished');
  //           setTimeout(async () => {
  //             const form = new FormData();
  //             const url = 'https://ddi556n39z2z8.cloudfront.net/' + fileName;
  //             form.append("fileName", fileName);
  //             form.append("userEmail", user.email);
  //             form.append("userName", user.userName);
  //             form.append("organization", user.organization || null);
  //             form.append("reps", reps);
  //             form.append("fileURL", url);
  //             try {
  //               const res = await axios.post(`/api/challenges/reply/${id}`, form, {
  //                  headers: {
  //                      "Authorization": `Bearer ${user.token}` // TODO: "Authorization"
  //                  },
  //              })
  //              const payload = res;
  //              challengeDispatch({ type: "REPLY", payload: payload.data });
  //              navigate(`/${id}`);
  //              setIsLoading(false);
  //          } catch (err) {
  //              console.log("[ERROR][handleSubmit]: " + err.message);
  //          }
  //         }, 2000)
  //       })
  //       .catch( async (error) => {
  //         await axios.post('/api/challenges/parent/S3error', {error: error.message})
  //         console.error(error);
  //       });

  //       console.log('finihesd')

  //       setUploadStart(false)
  //       setUploadFinish(true);
  //     } catch (error) {
  //       console.error(error);
  //       await axios.post('/api/challenges/parent/S3error', {error: error.message})
  //     }

  // };
  const uploadFile = async (file) => {
    try {
      setErrorMsg("");
      if (isFileSize) {
        setIsLoading(true);
        setUploadStart(true);
        const { fileName } = await uploadFileToS3(file, setProgress, true);
        setUploadStart(false);
        setUploadFinish(true);
        const url = "https://ddi556n39z2z8.cloudfront.net/" + fileName;
        const form = new FormData();
        console.log(user);
        form.append("userName", user.userName);
        form.append("userEmail", user.email);
        form.append("organization", user.organization);
        form.append("reps", reps);
        form.append("fileName", fileName);
        form.append("fileURL", url);

        const res = await axios.post(`/api/challenges/reply/${id}`, form, {
          headers: {
            Authorization: `Bearer ${user.token}`, // TODO: "Authorization"
          },
        });
        const payload = res;
        challengeDispatch({ type: "REPLY", payload: payload.data });
        navigate(`/${id}`);
        setIsLoading(false);
      }
    } catch (err) {
      console.log(err);
      setErrorMsg(connectionErrMsg);
      setUploadStart(false);
      setSnackbarOpen(true);
      setIsSubmitting(false);
      setProgress(0);
      Sentry.captureException(
        JSON.stringify({
          error: err.message,
          stack: err.stack,
          err: err,
        })
      );
    }
  };

  // React.useEffect(() => {
  //   if (isSubmitting && file) {
  //     uploadFile(file);
  //   }
  // }, [isSubmitting, file]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    const singleFile = fileRef?.current?.files[0] || file;
    await uploadFile(singleFile);
  };

  return (
    <div>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert onClose={handleClose} severity="error" sx={{ width: "100%" }}>
          {connectionErrMsg}
        </Alert>
      </Snackbar>
      <div
        className=" py-4 text-white flex 
                px-4
                sm:px-8
                md:px-10
                lg:px-28 
                xl:px-64
                "
      >
        <Link
          className="rounded-full bg-stone-500 py-2 px-2 cursor-pointer bg-opacity-40 hover:bg-stone-700"
          to={`/${id}`}
        >
          <AiOutlineArrowLeft />
        </Link>
      </div>
      <div
        className=" rounded-xl py-8
                px-4
                sm:px-8
                md:px-10
                lg:px-28 
                xl:px-64 "
      >
        <div className="lg:grid  lg:place-content-center lg:pt-12">
          <form className="grid rounded-xl lg:w-96" onSubmit={handleSubmit}>
            <h1 className="font-bold text-5xl pb-4 text-white">Beat it!</h1>
            <input
              className="rounded p-3  text-stone-300 pr-20 placeholder:text-stone-400 
                                mb-4 bg-stone-900 border-stone-600 border hover:border-red 
                                outline-none focus:border-red input:invalid input:text-white "
              placeholder="Reps"
              required
              type="number"
              onChange={(e) => setReps(e.target.value)}
              value={reps}
            />

            <input
              required
              accept="video/*"
              className="text-sm text-stone-400 w-full file:py-3 file:px-11  file:mr-6
              file:rounded-full file:bg-stone-900 file:cursor-pointer file:text-stone-400 file:border file:border-solid file:border-stone-600 
              hover:file:border-red pb-4"
              name="file"
              type="file"
              id="file"
              ref={fileRef}
              onChange={handleFileChange}
            />

            <div className="text-white text-opacity-80 ">
              <p>
                Big files can take time to upload,
                <br /> but the wait is worth it.
              </p>
            </div>
            <p className="text-white ">
              {uploadStart
                ? "Uploading..."
                : uploadFinish
                ? "Done - You’re on the Board!"
                : ""}
            </p>
            <div className="w-full h-full  py-2">
              <div
                className={
                  progress > 0
                    ? "bg-yellow text-xs font-medium text-black text-center p-1 py-2 rounded-full w-full"
                    : "text-xs font-medium text-black text-center p-1 rounded-full"
                }
                style={{ width: progress }}
              >
                {progress}%
              </div>
            </div>
            {errorMsg && <p className="text-red text-left pt-2">{errorMsg}</p>}
            <button
              disabled={isSubmitting}
              className="bg-red py-3 rounded px-28 hover:bg-hoverRed text-stone-900 font-semibold  hover:text-stone-200 mt-4"
            >
              Submit
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default CreateChild;
