import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { ChallengeContextProvider } from "./context/ChallengeContext";
import { AuthContextProvider } from "./context/AuthContext";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
Sentry.init({
  dsn: "https://9b4eaf3e986e475896ef4f9a8cd7730d@o573750.ingest.sentry.io/4504316984164352",
  integrations: [new BrowserTracing()],

  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 1.0,
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <AuthContextProvider>
      <ChallengeContextProvider>
        <App />
      </ChallengeContextProvider>
    </AuthContextProvider>
  </React.StrictMode>
);
