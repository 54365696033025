import React, { useState } from "react";
import axios from "../config/axios";
import { useChallengesContext } from "../hooks/useChallengeContext";
import { useAuthContext } from "../hooks/useAuthContext";
import { Checkbox, FormControlLabel, Snackbar, Alert } from "@mui/material";
import { useNavigate } from "react-router-dom";
import AWS from "aws-sdk";
import * as Sentry from "@sentry/react";
import { uploadFileToS3 } from "../services/upload.service";
const { v4: uuidv4 } = require("uuid");

//  AWS.config.update({
//   accessKeyId: process.env.REACT_APP_ACCESS_KEY,
//   secretAccessKey: process.env.REACT_APP_SECRET_ACCESS_KEY,
//   region: bucketRegion
// });

const s3 = new AWS.S3({
  region: process.env.REACT_APP_BUCKET_REGION,
  accessKeyId: process.env.REACT_APP_ACCESS_KEY,
  secretAccessKey: process.env.REACT_APP_SECRET_ACCESS_KEY,
});

const CreateParent = () => {
  const { user } = useAuthContext();
  const { challengeDispatch } = useChallengesContext();
  const [file, setFile] = useState(null);
  const [uploadStart, setUploadStart] = useState(false);
  const [uploadFinish, setUploadFinish] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [isFileSize, setIsFileSize] = useState(true);
  const [isloading, setIsLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [progress, setProgress] = useState(0);
  const navigate = useNavigate();

  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [reps, setReps] = useState("");
  const [isLessReps, setIsLessReps] = useState(false);
  const fileRef = React.useRef(null);
  const [snackbarOpen, setSnackbarOpen] = React.useState(false);
  const connectionErrMsg =
    "Your connection dropped please upload your video again!";
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setSnackbarOpen(false);
  };
  //Checking reps order
  const handleCheckBokChange = (event) => {
    if (isLessReps !== event.target.checked) {
      setIsLessReps(event.target.checked);
    } else {
      setIsLessReps(!event.target.checked);
    }
  };

  //Validating file size
  function checkFileSize(file) {
    // Check the size of the file in bytes
    const fileSizeInBytes = file.size;

    // Convert the file size from bytes to megabytes
    const fileSizeInMB = fileSizeInBytes / 1024 / 1024;

    // Set the state variable to true if the file is larger than 500MB
    if (fileSizeInMB > 500) {
      setIsFileSize(false);
      setErrorMsg("Please upload a video that is less than 500mb");
    }
  }

  //OnChange file checking

  const handleFileChange = (event) => {
    const selectedFile = fileRef?.current?.files[0] || event.target.files[0];
    checkFileSize(selectedFile);
    setFile(selectedFile);
  };

  // const handleSubmit = async (event) => {
  //     event.preventDefault();

  //     if (!file) {
  //       return;
  //     }
  //     const uuid = uuidv4();
  //     const fileName = `${uuid}.mp4`
  //     try {
  //       // Upload the file to S3
  //       console.log('start')
  //       // Upload the file to S3
  //       const params = {
  //         Bucket: process.env.REACT_APP_BUCKET_NAME,
  //         Key: fileName,
  //         Body: file,
  //         partSize: 5 * 1024 * 1024, // 5MB chunk sizes.
  //         queueSize: 1 // Number of concurrent upload requests
  //       };

  //       await s3.upload(params)
  //       .on('httpUploadProgress', (progress) => {
  //           const percent = Math.round((progress.loaded / progress.total) * 100);
  //           console.log(percent)
  //           setProgress(percent);
  //         })
  //       .promise()
  //       .then(() => {
  //         // This function will be called when the upload is complete
  //         console.log('Upload finished');
  //         setTimeout(async () => {
  //           const form = new FormData();
  //           const url = 'https://ddi556n39z2z8.cloudfront.net/' + fileName;
  //           form.append("fileName", fileName);
  //           form.append("userEmail", user.email);
  //           form.append("userName", user.userName);
  //           form.append("organization", user.organization || null);
  //           form.append("title", title);
  //           form.append("description", description);
  //           form.append("reps", reps);
  //           form.append("isLessReps", isLessReps);
  //           form.append("fileURL", url);
  //           try {
  //             const payload = await axios.post('/api/challenges/', form, {
  //               headers: {
  //                 'Content-Type': 'multipart/form-data'
  //               }
  //             })
  //             console.log(payload.data)
  //             await challengeDispatch({ type: "CREATE_CHALLENGE", payload: payload.data.SavedChallenge});
  //             navigate(`/${payload.data.SavedChallenge._id}`);
  //             setIsLoading(false);
  //           } catch (err) {
  //             console.log(err)
  //           }
  //       }, 2000)

  //       })
  //       .catch( async (error) => {
  //         // This function will be called if there is an error during the upload
  //         await axios.post('/api/challenges/parent/S3error', {error: error.message})
  //         console.error(error);
  //       });

  //       console.log('finihesd')

  //       setUploadStart(false)
  //       setUploadFinish(true);
  //     } catch (error) {
  //       console.error(error);
  //       await axios.post('/api/challenges/parent/S3error', {error: error.message})
  //     }

  // };

  // Form submission
  const uploadFile = async (file) => {
    try {
      setErrorMsg("");
      if (isFileSize) {
        setIsLoading(true);
        setUploadStart(true);
        const { fileName } = await uploadFileToS3(file, setProgress);
        setUploadStart(false);
        setUploadFinish(true);

        const form = new FormData();
        const url = "https://ddi556n39z2z8.cloudfront.net/" + fileName;
        form.append("fileName", fileName);
        form.append("userEmail", user.email);
        form.append("userName", user.userName);
        form.append("organization", user.organization || null);
        form.append("title", title);
        form.append("description", description);
        form.append("reps", reps);
        form.append("isLessReps", isLessReps);
        form.append("fileURL", url);

        const payload = await axios.post("/api/challenges/", form, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        console.log(payload.data);
        await challengeDispatch({
          type: "CREATE_CHALLENGE",
          payload: payload.data.SavedChallenge,
        });
        navigate(`/${payload.data.SavedChallenge._id}`);
        setIsLoading(false);
      }
    } catch (err) {
      console.log(err);
      setErrorMsg(connectionErrMsg);
      setUploadStart(false);
      setSnackbarOpen(true);
      setIsSubmitting(false);
      setProgress(0);
      Sentry.captureException(
        JSON.stringify({
          error: err.message,
          stack: err.stack,
          err: err,
        })
      );
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    const singleFile = fileRef?.current?.files[0] || file;
    await uploadFile(singleFile);
  };

  return (
    <div className="bg-black h-screen py-8 px-4 sm:px-8 md:px-10 lg:px-28  xl:px-64">
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert onClose={handleClose} severity="error" sx={{ width: "100%" }}>
          {connectionErrMsg}
        </Alert>
      </Snackbar>
      <div className=" rounded-xl  lg:grid  lg:place-content-center lg:pt-24  ">
        <form className="grid  rounded-xl" onSubmit={handleSubmit}>
          <div className="flex flex-col w-full h-full px-3 ">
            <h1 className="font-bold text-5xl pb-10 text-white ">
              Set A World Record!
            </h1>

            {/* Title */}
            <input
              required
              className="rounded p-3 w-full  text-stone-300 pr-20 placeholder:text-stone-400 mb-4 bg-stone-900 border-stone-600 border hover:border-red outline-none focus:border-red "
              placeholder="Challenge title"
              type="text"
              onChange={(e) => setTitle(e.target.value)}
              value={title}
              maxLength="18"
            />

            {/* Description */}
            <input
              required
              className="rounded w-full py-6 px-3 text-stone-300 pr-20 placeholder:text-stone-400 mb-4 
              block bg-stone-900 border-stone-600 border hover:border-red outline-none 
              focus:border-red "
              placeholder="Description"
              type="text"
              onChange={(e) => setDescription(e.target.value)}
              value={description}
              maxLength="200"
            />

            {/* Reps */}
            <input
              className="rounded p-3 w-full text-stone-300 pr-20 placeholder:text-stone-400 
              mb-4 bg-stone-900 border-stone-600 border hover:border-red 
              outline-none focus:border-red"
              placeholder="Score"
              required
              type="number"
              onChange={(e) => setReps(e.target.value)}
              value={reps}
            />

            <div className=" place-items-center place-content-between text-stone-400 flex mb-4">
              <p className=" w-max"> Best score is</p>
              <div className="flex">
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={!isLessReps}
                      onChange={handleCheckBokChange}
                      className="border-stone-400"
                      inputProps={{ "aria-label": "controlled" }}
                      style={{ color: "rgb(214 211 209)" }}
                    />
                  }
                  label="Highest"
                  labelPlacement="start"
                  className="rounded  text-stone-400  placeholder:text-stone-400 
                  text-center place-items-center place-content-center"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={isLessReps}
                      onChange={handleCheckBokChange}
                      className="border-stone-400"
                      inputProps={{ "aria-label": "controlled" }}
                      style={{ color: "rgb(214 211 209)" }}
                    />
                  }
                  label="Lowest"
                  labelPlacement="start"
                  className="rounded text-stone-400  placeholder:text-stone-400 
                  text-cente place-items-center place-content-center"
                />
              </div>
            </div>

            <input
              required
              accept="video/*"
              className="text-sm text-stone-400 w-full file:py-3 file:px-11  file:mr-6 pb-2
              file:rounded-full file:bg-stone-900 file:cursor-pointer file:text-stone-400 file:border file:border-solid file:border-stone-600 
              hover:file:border-red"
              name="file"
              type="file"
              id="file"
              ref={fileRef}
              onChange={handleFileChange}
            />
            <div className="w-full bg-gray-200 dark:bg-gray-700">
              <div className="text-white pt-2 pb-6 text-opacity-80">
                <p>
                  Big files can take time to upload, but the wait is worth it.
                </p>
                {errorMsg && (
                  <p className="text-red pt-2  text-start">{errorMsg}</p>
                )}
              </div>
              <p className="text-white">
                {uploadStart
                  ? "Uploading..."
                  : uploadFinish
                  ? "Done - You’re on the Board!"
                  : ""}
              </p>
              <div className="py-2">
                <div
                  className={
                    progress > 0
                      ? "bg-yellow text-xs font-medium text-black text-center p-1 leading-none rounded-full W-full py-2"
                      : "py-2 text-xs font-medium text-black text-center p-0.5 leading-none rounded-full"
                  }
                  style={{ width: progress }}
                >
                  {progress}%
                </div>
              </div>
            </div>

            <button
              disabled={isSubmitting}
              className="bg-red py-3 w-full rounded px-28 hover:bg-hoverRed  text-stone-900 font-semibold  hover:text-stone-200"
            >
              Submit
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default CreateParent;
