import axios from "../config/axios";
import * as Sentry from "@sentry/react";

const getSignedUrl = async (fileType) => {
  const signedUrlObject = await axios.get("/api/challenges/s3Url", {
    params: { fileType },
  });
  const data = signedUrlObject.data.data;
  console.log("signedUrlObject", data);

  return {
    signedUrl: data.uploadURL,
    fileName: data.fileName,
  };
};
const uploadFileOnSignedUrl = async (file, signedUrl, setProgress) => {
  const uploadResponse = await axios.put(signedUrl, file, {
    onUploadProgress: function (progressEvent) {
      const progress = Math.round(
        (progressEvent.loaded / progressEvent.total) * 100
      );
      setProgress(progress);
    },
    partSize: 5 * 1024 * 1024, // 5MB chunk sizes.
    queueSize: 1, // Number of concurrent upload requests
  });

  console.log("uploadResponse", uploadResponse);
};

export const uploadFileToS3 = async (file, setProgress, child = false) => {
  console.log(navigator.userAgent);
  const submitTime = new Date();
  const version = "v3";
  console.log("version", version);
  console.log(navigator.userAgent);
  try {
    //Get Signed URL
    var { signedUrl, fileName } = await getSignedUrl(file.type);
    console.log("file", file);
    await uploadFileOnSignedUrl(file, signedUrl, setProgress);
    return { fileName };
  } catch (error) {
    const errorObj = {
      errorMsg: error.message,
      code: error.code,
      data: error?.config?.data,
      error: error,
      userAgent: navigator.userAgent,
      submitTime: submitTime,
      catchTime: new Date(),
      version: "v2",
    };
    Sentry.captureException(
      JSON.stringify({ ...errorObj, ...{ signedUrl, fileName } })
    );
    const recordType = child ? "child" : "parent";
    await axios.post(`/api/challenges/${recordType}/S3error`, errorObj);
    throw error;
  }
};
